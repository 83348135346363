<template>
  <LoadingOverlay v-if="isLoading" />
  <PageHeader hidePadding="top">
    <template #headerContent>
      <div class="col-sm-1 col-md-2 col-lg-4"></div>
      <div class="col-12 col-sm-10 col-md-8 col-lg-4 mt-3 mb-3">
        <GreenifiedButton
          type="black"
          :disabled="!selectedProject"
          :text="$t('button.selectProjectAndContinue')"
          @click="selectProject(selectedProject)"
        />
        <div class="col-sm-1 col-md-2 col-lg-4"></div>
      </div>
    </template>
  </PageHeader>
  <GreenifiedCard :grColor="false" :hidePadding="'top'">
    <template #cardContent>
      <div class="row mt-3 justify-content-between">
        <template v-for="project in projects" :key="project.projektnr">
          <ProjectButton
            v-if="project.active === '1'"
            :project="project"
            :isSelected="selectedProject === project.projektnr"
            @selected="
              (projectNumber) => {
                selectedProject = projectNumber;
              }
            "
          />
        </template>
      </div>
    </template>
  </GreenifiedCard>
</template>

<script>
import GreenifiedButton from "@/shared/components/GreenifiedButton.vue";
import GreenifiedCard from "../../shared/components/GreenifiedCard.vue";
import item from "../services/api/item";
import ProjectButton from "./ProjectButton.vue";
import PageHeader from "@/shared/components/PageHeader.vue";
import { mapActions } from "vuex";
import LoadingOverlay from "@/shared/components/LoadingOverlay.vue";
import router from "../router";

export default {
  data() {
    return {
      selectedProject: null,
      projects: null,
      isLoading: false,
    };
  },
  components: {
    GreenifiedCard,
    GreenifiedButton,
    ProjectButton,
    PageHeader,
    LoadingOverlay,
  },
  methods: {
    ...mapActions({
      setProjectSettings: "setProjectSettings",
    }),
    selectProject(projectNumber) {
      this.isLoading = true;
      this.setProjectSettings(projectNumber)
        .then(() => {
          this.isLoading = false;
          router.push({ name: this.$route.redirectedFrom?.name ?? "welcome" });
        })
        .catch((e) => {
          this.$httpError(e, "SelectProject - setProjectSettings");
        });
    },
    getProjects() {
      this.isLoading = true;
      item
        .getProjects()
        .then((response) => {
          this.projects = response.data;
          if (this.projects.length === 0) {
            return this.$router.push({
              name: "notAuthorized",
              params: { authType: "P" },
            });
          }
          this.isLoading = false;
        })
        .catch((e) => {
          this.$httpError(e, "SelectProject - getProjects");
        });
    },
  },
  mounted() {
    this.getProjects();
  },
};
</script>

<style scoped>
.loader-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
</style>
